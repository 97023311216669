<template>
	<div class="order_wrap">
		<MStepper />
		<v-container class="">
			<div class="txt_box px-6">
				<h1 class="tit_page">수납 (개인 가상계좌)</h1>
				<p class="txt_body1 mb-8">
					아래 계좌로 지정된 금액을 입금해주세요.
					<br />수납 금액은<span class="primary--text">
						{{ dpParams.amount | comma }} 원</span
					>
					입니다.
				</p>
			</div>
			<div class="line_box px-6">
				<dl>
					<dt>가상계좌</dt>
					<dd>{{ dpParams.account }}</dd>
				</dl>
				<dl>
					<dt>납부액</dt>
					<dd>{{ dpParams.amount | comma }} 원</dd>
				</dl>
				<dl>
					<dt>납부기한</dt>
					<dd>{{ dpParams.date }}</dd>
				</dl>
			</div>
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					확인
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn>
			</div>
			<CustomRecpDialog ref="recpPop" />
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
// import { vaccountInstantTransfer } from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
// import { sendAlimTalk, fetchEContract } from '@/apis/order.ec.api'
import moment from 'moment'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객수납
			},
			vaccountParams: {
				TC_VTACCT: {},
				TO_DISPLAY: []
			},
			dpParams: {
				account: '',
				date: '',
				amount: ''
			},
			mthd: '16',
			status: '' // 서명완료 후 재진입
		}
	},
	mounted() {
		const { status } = this.$route.query
		if (status) {
			this.status = status
		}

		this.recpInfos = this.$store.getters['recp/recpInfos']
		if (!this.recpInfos.mthd) this.recpInfos.mthd = this.mthd
		this.vaccountParams = this.$store.getters['recp/vaccountParams']

		this.$log('가상계좌생성 후 수납 여부 isNew: ', this.vaccountParams.isNew)

		if (this.vaccountParams.isNew) {
			// 가상계좌생성 후 수납
			let bankName = this.vaccountParams.bankName
				? this.vaccountParams.bankName
				: ''
			this.dpParams.account = `${bankName} ${this.vaccountParams.TO_VTINFO.SUACC}`
			this.dpParams.date = ''
			this.dpParams.amount = this.recpInfos.amount
		} else {
			this.vaccountParams.TO_DISPLAY.forEach(dp => {
				const txt = dp.ZWEB_DIS_TEXT.trim()

				switch (dp.ZWEB_DIS_CD) {
					case '33':
						this.dpParams.account = dp.ZWEB_DIS_TEXT.trim()
						break
					case '40':
						this.dpParams.date = dp.ZWEB_DIS_TEXT.trim()
						break
					case '51':
						this.dpParams.amount = txt.substr(0, txt.indexOf('.'))
						break
				}
			})
		}

		//SAP에서 납부기한 내려오지 않아서 임시처리
		this.dpParams.date =
			this.dpParams.date || moment().format('YYYY년 MM월 DD일 오후 5시')
		this.$store.dispatch('recp/setDpParams', this.dpParams)
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async chkReturn(prm, rtn, tcObj) {
			if (rtn.TYPE === 'S') {
				if (this.status === '106') {
					// 직접인증 가상계좌 서명완료 후 재진입
					await this.$alert({
						message:
							'서명이 완료됐습니다.<br />가상 계좌로 기한 내 납부하도록 안내해주세요.',
						okText: '확인'
					})
				} else {
					await this.$alert({
						message:
							'개인 가상계좌 입금을 선택하였습니다.<br />가상계좌 정보는 설치정보 작성 완료 후 안내 됩니다.<br />설치 정보 화면으로 이동합니다.',
						okText: '설치정보 화면으로 이동'
					})
				}

				// TODO store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				const fetchRes = await fetchWebOrderInfoById(mobOrderNo)
				let webParams = fetchRes.resultObject
				let order = JSON.parse(webParams.orderInfoJson)
				order = Object.assign(order, {
					recp: {
						recpParams: this.$store.getters['recp/recpParams'],
						recpInfos: this.$store.getters['recp/recpInfos'],
						recpBankResult: tcObj,
						isCustSelfPay: false,
						vaccountParams: this.vaccountParams,
						dpParams: this.dpParams
					}
				})

				if (this.status === '106') {
					webParams.step = '04' // 수납
					webParams.statusCode = '106' // 서명완료
				} else {
					webParams.step = '05' // 설치정보
					webParams.statusCode = '107' // 설치대기
				}
				webParams.orderInfoJson = JSON.stringify(order)

				const postRes = await postWebOrderInfoById(mobOrderNo, webParams)
				this.$log('postRes: ', JSON.stringify(postRes))
				if (this.status === '106') {
					// 진행현황
					// 알림톡 발송
					// let ecParams = {
					// 	mobOrderNo: this.$store.getters['verify/mobOrderNo']
					// }
					// const ecRes = await fetchEContract(ecParams)
					// const orderList = ecRes.resultObject.data[0].setup
					// let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
					// let count =
					// 	orderList[0] && orderList[0].length > 0
					// 		? orderList[0].length - 1
					// 		: ''
					// let ordNoStr = ''
					// orderList.forEach((res, i) => {
					// 	if (i === 0) {
					// 		ordNoStr = `${res.ORDER_NO}`
					// 	} else {
					// 		ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
					// 	}
					// })

					// let atParams = {
					// 	reqCd: '2', // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
					// 	gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
					// 	name: this.$store.getters['verify/custName'],
					// 	phone: this.$store.getters['verify/custPhone'],
					// 	orderNo: ordNoStr, // TODO orderList에서 추출
					// 	url: `${process.env.VUE_APP_FRT_URL}/order/confirm`, // 주문확정 링크
					// 	orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
					// 	customerNo: this.$store.getters['verify/custNo'],
					// 	codyNo: this.$store.getters['common/codyNo'],
					// 	codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
					// 	goodsNm: prdNm,
					// 	goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
					// 	onceAmt: this.$store.getters['pay/recpAmt'],
					// 	monthAmt: this.sumAmt.MONTH_AMT_TOTAL
					// }

					// await sendAlimTalk(atParams)

					this.$router.push({ name: 'order-status' })
				} else {
					// 설치정보
					this.$router.push({ name: 'order-inst' })
				}
			} else {
				const options = {
					title: '다음과 같은 사유로 수납이 거부됐습니다.',
					guide: '수납방법을 재선택 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '수납방법 재선택',
					cancelText: ''
				}

				const popRes = await this.$refs.recpPop.open(options)

				if (popRes) {
					// 수납방법 재선택
					this.onResetPayMethod()
				}
			}
		},
		async goRecp() {
			let prm = {}
			if (this.vaccountParams.isNew) {
				// 가상계좌생성 후 수납
				prm = {
					bankc: this.vaccountParams.bankc,
					suacc: this.vaccountParams.TO_VTINFO.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.recpInfos.name,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: this.vaccountParams.mthd,
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
			} else {
				prm = {
					bankc: this.vaccountParams.TC_VTACCT.BANKC,
					suacc: this.vaccountParams.TC_VTACCT.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.vaccountParams.TC_VTACCT.NAME1,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: this.vaccountParams.mthd,
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
			}

			// const res = await vaccountInstantTransfer(prm)
			const rtn = {
				TYPE: 'S'
			}
			const vtInfo = {
				BANKC: prm.bankc,
				SUACC: prm.suacc
			}
			await this.chkReturn(prm, rtn, vtInfo)
		}
	}
}
</script>
